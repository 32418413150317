import {Component, Input, OnInit} from '@angular/core';
import {PayReport} from "src/app/models/pay-report";

@Component({
  selector: 'app-pay-report-summary',
  templateUrl: './pay-report-summary.component.pug',
  styleUrls: ['./pay-report-summary.component.sass']
})
export class PayReportSummaryComponent implements OnInit {
  @Input() payReport: PayReport;

  constructor() { }

  ngOnInit(): void {
  }

}
