import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx'
import {Observable} from "rxjs";
import {TimeUnit} from "src/app/models/time-unit";
import {map} from "rxjs/operators";
import {Sheet2JSONOpts} from "xlsx";

type Row = {
  [col: string]: string
};

@Injectable({
  providedIn: 'root'
})
export class ReportParserService {

  constructor() { }

  /**
   * Parses an XLSX file from a file upload event (file input button).
   *
   * @param event The upload event (from the HTML form)
   * @param sheet The number of the sheet to parse (default: 0).
   * @param sheet2JSONOpts Additional options to pass to the XLSX parser.
   */
  processFile(event, sheet= 0, sheet2JSONOpts: Sheet2JSONOpts = {}): Observable<Row[]> {
    return new Observable((observer) => {
      const target: DataTransfer = <DataTransfer>(event.target);
      if (target.files.length !== 1) throw new Error('Cannot use multiple files');
      const reader: FileReader = new FileReader();

      reader.onload = (e: any) => {
        const wb: XLSX.WorkBook = XLSX.read(e.target.result, {type: 'binary', cellDates: true});
        const ws: XLSX.WorkSheet = wb.Sheets[wb.SheetNames[sheet]];

        observer.next(XLSX.utils.sheet_to_json(ws, sheet2JSONOpts));
        observer.complete();
      };
      reader.readAsBinaryString(target.files[0]);
    });
  }

  processAMC(event): Observable<{[name: string]: TimeUnit[]}> {
    return this
      .processFile(event, 0, { range: 2 })
      .pipe(map(this.parseAMC));
  }

  parseAMC(rows: Row[]): { [name: string]: TimeUnit[] } {
    const timeUnits = rows.map(ReportParserService.amcRowToTimeUnit);
    return ReportParserService.groupBy(timeUnits, x => x.doctorName);
  }

  private static groupBy<T>(arr: T[], fn: (T) => string): { [name: string]: T[] } {
    return arr.reduce((acc: { [name: string]: T[] }, t: T) => {
      (acc[fn(t)] = acc[fn(t)] || []).push(t);
      return acc;
    }, {});
  }

  private static amcRowToTimeUnit(row: Row): TimeUnit {
    let tf = parseFloat(row['Time Units']);
    if(isNaN(tf)) {
      if (row["BegTime"] && row["End Time"]) {
        tf = unitsBetweenTimes(row["BegTime"], row["End Time"]);
      } else {
        tf = 0;
      }
    }
    return {
      doctorName: row['Doctor Name'],
      accountNumber: row['Account Number'],
      doctorId: parseInt(row['Doctor']),
      incidentId: row['Incident Id'],
      incidentReportingClass: 'Inc Reporting Class',
      patient: row['Patient'],
      serviceDate: row['Service Date'] as unknown as Date, // XLSX.read is returning dates as dates not strings ( see cellDates:true )
      dosDay: null,
      mdFlag: row['MD Flag'],
      startTime: row['BegTime'],
      endTime: row['End Time'],
      timeUnits: tf,
      exempt: false,
      facility: row['Facility'],
    };
  }
}


// allow comparing 15:02 and 17:11
export function ltTime (a:string,b:string): boolean {
  return parseFloat(a.replace(":", ".")) < parseFloat(b.replace(":", "."));
}
// allow comparing 15:02 and 17:11
export function gtTime (a:string,b:string): boolean {
  return parseFloat(a.replace(":", ".")) > parseFloat(b.replace(":", "."));
}
// grab only the 24H time portion of a date
export function timeFromDate (a:Date | string): string {
  if(a instanceof Date)
    return a.getHours() + ":" + ("0" + a.getMinutes().toString()).substr(-2);
  else 
    return timeFromDate(new Date(a));
}
// return 15:02 from 15:02 and 17:11
export function minTime(t1:string, t2: string): string {
  return ltTime(t1, t2)? t1: t2;
}
// return 17:11 from 15:02 and 17:11
export function maxTime(t1:string, t2:string): string {
  return gtTime(t1, t2)? t1: t2;
}

export function unitsBetweenTimes(t1:string, t2:string): number {
  const times1 = t1.split(":").map(s => parseInt(s)), times2 = t2.split(":").map(s => parseInt(s))
  const hours = times2[0] - times1[0];
  const minutes = times2[1] - times1[1];
  let units = hours * 2;
  units += (minutes / 30);
  return Math.floor(units * 100)/100;
}