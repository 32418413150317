import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ReportParserService, gtTime, ltTime, timeFromDate,minTime, maxTime, unitsBetweenTimes} from "src/app/services/report-parser.service";
import {filter, min, takeWhile, tap} from "rxjs/operators";
import {TimeUnit} from "src/app/models/time-unit";
import {NgxSpinnerService} from "ngx-spinner";
import {MatVerticalStepper} from "@angular/material/stepper";
import {PayReport} from "src/app/models/pay-report";
import {FixedUnit} from 'src/app/models/fixed-unit'
import {PayReportService} from "src/app/services/pay-report.service";
import {Router} from "@angular/router";

const SATURDAY=6, SUNDAY=0;
interface HTMLInputEvent extends Event {
  target: HTMLInputElement & EventTarget;
}

@Component({
  selector: 'stepper',
  templateUrl: './stepper.component.pug',
  styleUrls: ['./stepper.component.sass']
})
export class StepperComponent implements OnInit, OnDestroy {
  /** The raw data we've parsed from the Excel files. */
  rawData: Object = {};

  /**
   * Data parsed from the AMC file. This is a mapping from doctor name to an
   * array of TimeUnits.
   */
  amcData: { [name: string]: TimeUnit[] } = null;

  /** A mapping from Employee Name to Employee ID. */
  nameMap: { [employeeName: string]: string } = {};

  /** A mapping from Doc Abbr to Employee ID. */
  codeMap: { [docAbbr: string]: string } = {};

  /** The output. */
  payData: Object = {};

  /** Is this component still alive and kickin'? */
  alive = true;

  /** The stepper component. */
  @ViewChild('stepper') private stepper: MatVerticalStepper;

  constructor(private reportParserService: ReportParserService,
              private payReportService: PayReportService,
              private spinner: NgxSpinnerService,
              private router: Router) {}

  ngOnInit() { }

  ngOnDestroy() { this.alive = false; }

  skeleton(name:string, code:string) {
    return {name: name, abbr: code, fixedUnitEntries:[]}
  }

  inventCodeForName(name:string): string {
    const naturalNameOrder = (name:string) => {
      if(name.includes(',')) {
        let parts = name.split(",");
        // might have a title like dr, phd, etc
        if(parts[1].length > 3) {
          return parts[1] + " " + parts[0];
        } else {
          return name;
        }
      } else {
        return name;
      }
    }
    const identity = i => i;
    const initial = (s:string): string => s[0].toUpperCase();
    
    const initials:string = naturalNameOrder(name).split(" ").filter(identity).map(initial).join("");
    return "_" + initials;
  }

  /**
   * Processes a file upload event triggered by a file button on the page.
   *
   * @param event
   * @param type
   * @param sheet
   */
  processFile(event, type, sheet= 0){
    this.reportParserService
        .processFile(event, sheet)
        .pipe(takeWhile(_ => this.alive))
        .subscribe(x => {
          this.rawData[type] = x;
          this.stepper.next();
        });
  }

  /**
   * Process the upload of the AMC file.
   * @param event
   */
  processAMC(event): void {
    this.spinner.show('amc');
    this.reportParserService
        .processAMC(event)
        .pipe(tap(_ => this.spinner.hide('amc')))
        .subscribe(
           x => {
             this.amcData = x;
             this.stepper.next();
           },
           err => { window.alert('Invalid file supplied!'); });
  }

  /**
   * The status string displayed after the AMC step.
   */
  get amcStepStatus(): string {
    if (!this.amcData) return "";

    const doctors = Object.keys(this.amcData).length;
    const records = Object.values(this.amcData)
      .map(x => x?.length)
      .reduce((x,y) => x+y);

    return `(Found ${records} records from ${doctors} doctors)`;
  }

  /**
   * Are we ready to begin processing this report?
   */
  isProcessReady(): boolean {
    return this.amcData
      && ['call-pay', 'card-report']
        .map(k => this.rawData[k])
        .every((x : Object[]) => x && x.length);
  }

  processData(){
    this.populateMapsAndCallPay();
    this.greenCardSpecialCases();
    this.populateTimeUnits();
    this.populateFixedUnits()
    this.createGreenCardsForTimes();
    this.stepper.next();

    // sort time units
    Object.keys(this.amcData).forEach(drCode => {
      this.amcData[drCode].sort((a:TimeUnit, b:TimeUnit):number => {
        if(a.serviceDate < b.serviceDate) return -1;
        if(a.serviceDate > b.serviceDate) return 1;
        if(a.startTime < b.startTime) return -1;
        if(a.startTime > b.startTime) return 1;
        return 0;
      })
    })

    console.log(this.payData);
    const reports: PayReport[] = Object.entries(this.amcData).map((x: [string, TimeUnit[]], i) => {
      return {
        id: i,
        name: x[0],
        date: Date.now().toString(),
        month: Date.now().toString(),
        fixedUnits: this.fixedUnits(x[0]),
        timeUnits: x[1],
        callPayUnits: this.callPayUnits(x[0]),
        doctorCode: this.doctorCodeFor(x[0])
      };
    });
    this.payReportService.clearAndAddMultiple(reports)
      .pipe(takeWhile(_ => this.alive))
      .subscribe(x => {
        if (reports.length) {
          this.router.navigate(['/facilities']);
        }
      });
  }

  createGreenCardsForTimes() {
    Object.entries(this.amcData).forEach(([name, timeUnits]) => {
      timeUnits.forEach(tu => {
        const startTime = tu.startTime,
          endTime = tu.endTime;
        if (!startTime || !endTime) return;
        const date = tu.serviceDate;
        
        const isWeekend = [SUNDAY, SATURDAY].includes(date.getDay());
        const isEarly = ltTime(startTime, '6:00');
        const isLate = gtTime(endTime, '18:00');
          
        // if there is an entry in the all cards report whose StartTime is before 09:00 or whose end time is after 18:00 or ROW is \in (Sat, Sun) then this is an overtime entry and we need to create a time entry for the doctor. Otherwise, escape.
        // find a paired green card 
        // if doesn't exist, make one
        let filter = (fu:FixedUnit) => false;
        if (isWeekend) {
          filter = (fu:FixedUnit) => fu.codeLetters == 'Green Card' && fu.date == date;
        } else if(isEarly) {
          filter = (fu:FixedUnit) => fu.codeLetters == 'Green Card' && fu.date == date && ltTime(fu.startTime, '6:00');
        } else if (isLate) {
          filter = (fu:FixedUnit) => fu.codeLetters == 'Green Card' && fu.date == date && gtTime(fu.startTime, '18:00');
        } else {
          return
        }
        let id = this.getIdFromName(name);
        if (parseInt(id,10) < 0) {
          console.error({id,name})
        } else if (!id) {
          id = (0 - Object.keys(this.payData).length).toString();
          const code = this.inventCodeForName(name);
          this.payData[id] = this.skeleton(name, code);
          this.nameMap[name.toLowerCase()] = id;
          this.codeMap[code] = id;
          console.error({name})
        }
        const greenCards = this.payData[id].fixedUnitEntries.filter(filter);
        // if it does exist, make it inclusive if it isn't already.
        if(greenCards.length == 1) {
          const greenCard = greenCards[0] as FixedUnit;
          if(isWeekend) {
            greenCard.startTime = minTime(greenCard.startTime, startTime);
            greenCard.endTime = maxTime(greenCard.endTime, endTime);
          } else if(isEarly) {
            greenCard.startTime = minTime(greenCard.startTime, startTime);
            greenCard.endTime = maxTime(greenCard.endTime, '6:00');
          } else if (isLate) {
            greenCard.startTime = minTime(greenCard.startTime, '18:00');
            greenCard.endTime = maxTime(greenCard.endTime, endTime);
          }
          greenCard.units = unitsBetweenTimes(greenCard.startTime, greenCard.endTime);
        } else if (greenCards.length === 0) {
          if(isWeekend) {
            this.payData[id].fixedUnitEntries.push({
              startTime: startTime,
              endTime: endTime,
              codeLetters: "Green Card",
              date: date,
              units: unitsBetweenTimes(startTime, endTime)
            } as FixedUnit)
          } else if (isEarly) {
            this.payData[id].fixedUnitEntries.push({
              startTime: startTime,
              endTime: '06:00',
              codeLetters: "Green Card",
              date: date,
              units: unitsBetweenTimes(startTime, '06:00')
            } as FixedUnit)
          } else if (isLate) {
            this.payData[id].fixedUnitEntries.push({
              startTime: '18:00',
              endTime: endTime,
              codeLetters: "Green Card",
              date: date,
              units: unitsBetweenTimes('18:00', endTime)
            } as FixedUnit)
          }
        } else {
          console.error("Multiple overlapping green cards")
        }
      });
    });
  }

  fixedUnits(name:string): FixedUnit[] {
    const id = this.nameMap[name.toLowerCase()]
    if (id && this.payData[id])
      return this.payData[id].fixedUnitEntries;
    else
      return [];
  }

  doctorCodeFor(name:string): string {
    const id = this.nameMap[name.toLowerCase()];
    if (!id) return null;
    const candidates = Object.entries(this.codeMap).filter(entry => entry[1] == id);
    if (candidates.length === 0) return null;
    return candidates[0][0];
  }

  populateMapsAndCallPay(){
    // Create maps
    this.rawData['call-pay'].forEach((callPay) =>{
      const empId = callPay['Emp #']
      if(empId && empId != 'Emp #'){
        this.nameMap[callPay['Name'].toLowerCase()] = empId;
        this.codeMap[callPay['Doc Abbr'].toUpperCase()] = empId;
        this.payData[empId] = this.skeleton(callPay['Name'], callPay['Doc Abbr']);
      }
    });

    // Callpay hours
    this.rawData['call-pay'].forEach((callPay) =>{
      if(callPay['DOS Day'] != 'Total')
        return;
      const id = this.getIdFromCode(callPay['Doc Abbr'])
      this.payData[id].callPay = callPay['Units'].toFixed(2)
    });
    console.log("populateMapsAndCallPay ran")
  }
  getIdFromCode(code){
    return this.codeMap[code.toUpperCase()];
  }

  getIdFromName(name){
    return this.nameMap[name.toLowerCase()];
  }

  getNameFromCode(code:string): string {
    const codeIndex = Object.values(this.nameMap).indexOf(this.codeMap[code]);
    const drName = Object.keys(this.nameMap)[codeIndex].toUpperCase();
    return drName;
  }

  getAmcData(code: string): TimeUnit[] {
    return this.amcData[this.getNameFromCode(code)] || [];
  }

  getAMCCompEntry(code:string, date:Date, startTime:string, endTime:string): TimeUnit {
    const hasOverlap = (ts1, ts2, te1, te2): Boolean => ((ltTime(ts1, ts2) && gtTime(te1, ts1)) || (ltTime(ts1, te2) && gtTime(te1, te2))); 
    const getOverlap = (times: TimeUnit[]) => times.filter(r => hasOverlap(startTime, r.startTime, endTime, r.endTime));
    this.amcData[0];
    const amc = this.getAmcData(code).
      filter(r => r.serviceDate == date);
    const overlappingEntry = getOverlap(amc);
    if (overlappingEntry.length === 0) return null;
    if (overlappingEntry.length === 1) return overlappingEntry[0];
    throw new Error("This green card overlaps with multiple timeunits");
  }

  greenCardSpecialCases(): void {
    let code = null;
    this.rawData['card-report'].forEach((card)=>{
      // set the doc
      if(card['Name Code'])
        code = card['Name Code']
      if(card['Units'] =='Total_Units:'){
        return;
      }
      if(card['TypeCodeLetters'] == 'Green Card') {
        const startTime = timeFromDate(card['StartTime']),
          endTime = timeFromDate(card['EndTime']);
        const units = parseInt(card['Units'], 10);
        const date = card['DateDone'];
        const isWeekend = [SUNDAY, SATURDAY].includes(date.getDay());
        
        if(!ltTime(startTime, '6:00') && !gtTime(endTime, '18:00') && !isWeekend) return; // if there is an entry in the all cards report whose StartTime is before 09:00 or whose end time is after 18:00 or ROW is \in (Sat, Sun) then this is an overtime entry and we need to create a time entry for the doctor. Otherwise, escape.
        
        const f:FixedUnit = {
          date: card['DateDone'],
          codeLetters: 'Green Card',
          startTime: startTime,
          endTime: endTime,
          units: units,
        };
          
        this.payData[this.getIdFromCode(code)].fixedUnitEntries.push(f)
      }
      if(card['Units'] =='Total_Units:'){
        return;
      }
    })
  }

  /**
   * Populates the time units from the AMC report.
   */
  populateTimeUnits(){
    for (const [key, value] of Object.entries(this.amcData)) {
      let id = this.getIdFromName(key);
      if (!id) {
        const code = this.inventCodeForName(key);
        id = (-1 - Object.entries(this.nameMap).length).toString();
        this.nameMap[key] = id;
        this.codeMap[key] = code;
        this.payData[id] = this.skeleton(key, code);
        console.error(`NO ID FOUND FOR: "${key}" invented ID: "${id}"`);
      } else {
        console.log(id);
      }
      this.payData[id]['timeUnits'] =
        value
          .map(x => x.timeUnits)
          .reduce((x, y) => x+y);
    }
  }

  populateFixedUnits(){
    let code = null;
    this.rawData['card-report'].forEach((card)=>{
      // set the doc
      if(card['Name Code'])
        code = card['Name Code']
      if(card['Units'] =='Total_Units:'){
        this.payData[this.getIdFromCode(code)].fixedUnits = card['__EMPTY'].toFixed(2);
        return;
      } else if(card['TypeCodeLetters'] && card['TypeCodeLetters'] !== 'Green Card') {
        const startTime = timeFromDate(card['StartTime']),
          endTime = timeFromDate(card['EndTime']);
        const units = parseInt(card['Units'], 10);
        const f:FixedUnit = {
          date: card['DateDone'],
          codeLetters: card['TypeCodeLetters'],
          startTime: startTime,
          endTime: endTime,
          units: units,
        }
        this.payData[this.getIdFromCode(code)].fixedUnitEntries.push(f)
      }
    })
  }
  sumUnits(units){
    return (parseFloat(units.fixedUnits) + parseFloat(units.timeUnits) + parseFloat(units.callPay)).toFixed(2);
  }
  
  callPayUnits(name) {
    return this.rawData['call-pay'].filter(rowCP => {
      if (!rowCP["Name"] || !name) return false;
      return rowCP['Name'].toUpperCase() === name.toUpperCase();
    }).filter(rowCP=>{
      return rowCP["DOS Day"] != "Total";
    }).map(rowCP => ({
      date: rowCP["DOS"],
      dosDay: rowCP["DOS Day"],
      empId: rowCP["Emp #"],
      docAbbr: rowCP["Doc Abbr"],
      name: rowCP["Name"],
      callType: rowCP["Call Type"],
      possibleHours: parseFloat(rowCP["Possible Hours"]),
      paidHours: parseFloat(rowCP["Paid Hours"]),
      units: parseFloat(rowCP["Units"]),
    }));
  }
}
