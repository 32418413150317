import { Injectable } from '@angular/core';
import {PayReport, totalUnits } from "src/app/models/pay-report";
import {BehaviorSubject, Observable, of} from "rxjs";
import {map, tap} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class PayReportService {

  private reportsSubject = new BehaviorSubject<PayReport[]>(null);
  private facilitiesSubject = new BehaviorSubject<string[]>([]);
  
  constructor() { }

  aggregates(all) {
    const unitsByRow = all.map((report) => {
      report.totalUnits = totalUnits(report);
      return report.totalUnits;
    }).filter(v => !isNaN(v));
    let averageUnits = unitsByRow.reduce((accum, val) => accum + val, 0) / unitsByRow.length;
    all.forEach(report => report.averageUnits = averageUnits);
  }

  index(): Observable<PayReport[]> {
    const tmp = sessionStorage.getItem('TEMPDELETETHIS');
    if (this.reportsSubject.getValue() === null && tmp) this.reportsSubject.next(JSON.parse(tmp));
    
    const cached = this.reportsSubject.getValue() || [];
    this.aggregates(cached); // side effects
    const facilities = Object.values(cached).map(report => report.timeUnits.map(tu => tu.facility)).reduce((a, f) => a.concat(f), []).filter((v,i,a) => v && a.indexOf(v) === i);
    this.facilitiesSubject.next(facilities);
    
    // END DELETE
    return this.reportsSubject.asObservable();
  }


  get(id: number): Observable<PayReport> {
    return this.index().pipe(
      map(reports => reports.find(x => x.id === id)),
    );
  }

  clear(): Observable<boolean> {
    sessionStorage.removeItem('TEMPDELETETHIS');

    this.reportsSubject.next([]);
    return of(true);
  }

  add(payReport: PayReport): Observable<boolean> {
    const reports = this.reportsSubject.getValue() || [];
    reports.push(payReport);
    this.reportsSubject.next(reports);
    return of(true);
  }

  clearAndAddMultiple(payReports: PayReport[]): Observable<boolean> {
    this.aggregates(payReports);
    sessionStorage.setItem('TEMPDELETETHIS', JSON.stringify(payReports));
    this.reportsSubject.next([...payReports]);
    return of(true);
  }

  facilities() {
    return this.facilitiesSubject.asObservable();
  }

  async exemptFacility(facility, exempt) {
    const reports = this.reportsSubject.getValue() || [];
    reports.forEach(payReport => payReport.timeUnits.forEach(tu => {
      if (tu.facility == facility) {
        tu.exempt = exempt;
      }
    }));
    sessionStorage.setItem('TEMPDELETETHIS', JSON.stringify(reports));
    this.reportsSubject.next([...reports]);
  }
}
