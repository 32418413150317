import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { PayReport, totalUnits } from "src/app/models/pay-report";
import { PayReportService } from "src/app/services/pay-report.service";
import {Router} from "@angular/router";
import {Observable} from "rxjs";

const sum = (property) => (sum, record) => sum + record[property];

@Component({
    selector: 'app-employees-summary',
    templateUrl: './employees-summary.component.pug',
    // styleUrls: ['./employees-summary.component.sass']
})
export class EmployeesSummaryComponent {
    @Input() payReport: PayReport;
    
    reports$: Observable<PayReport[]>;
    alive = true;

    constructor(private payReportService: PayReportService, private router: Router) {
        this.reports$ = this.payReportService.index();
    }

    totalUnitsDisplay(payReport) {
        return payReport.totalUnits.toFixed(2);
    }

    averageUnitsDisplay(payReport) {
        return payReport.averageUnits.toFixed(2);
    }

    percentOfAverage(payReport) {
        return Math.floor(100 * payReport.totalUnits / payReport.averageUnits);
    }
    fixedUnitsDisplay(payReport) {
        return payReport.fixedUnits.reduce(sum('units'), 0).toFixed(2);
    }
    callPayUnitsDisplay(payReport) {
        return payReport.callPayUnits.reduce(sum('units'), 0).toFixed(2);
    }
    timeUnitsDisplay(payReport) {
        return payReport.timeUnits.filter(tu => !tu.exempt).reduce(sum('timeUnits'), 0).toFixed(2);
    }
}