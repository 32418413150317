export interface TimeUnit {
  doctorName: string,
  accountNumber: string,
  doctorId: number,
  incidentId: string,
  incidentReportingClass: string,
  patient: string,
  serviceDate: Date,
  dosDay?: string,
  mdFlag: string,
  startTime: string,
  endTime: string,
  timeUnits: number,
  exempt: boolean,
  facility: string,
}

export function dateToMMDDYYYY(date:string | Date):string {
  if (typeof date == 'object' && date instanceof Date) {
    return (date.getMonth() + 1).toString() + "/" + date.getDate().toString() + "/" + date.getFullYear().toString();
  } else {
    return dateToMMDDYYYY(new Date(date));
  }  
}
