import {Component, Input} from '@angular/core';
import {FixedUnit } from "src/app/models/fixed-unit";
import { PayReport } from 'src/app/models/pay-report';
import { TimeUnit } from 'src/app/models/time-unit';

@Component({
  selector: 'app-fixed-unit-table',
  templateUrl: './fixed-unit-table.component.pug',
  styleUrls: ['./fixed-unit-table.component.sass']
})
export class FixedUnitTableComponent {
  @Input() fixedUnits: FixedUnit[];
  @Input() showPatients: boolean;
  @Input() showFixed: boolean;
  @Input() payReport: PayReport;

  constructor() { }


  rows() {
    return this.fixedUnits;
  }

  code(): string {
    if(this.payReport) {
      return this.payReport.doctorCode;
    } else {
      return null;
    }
  }

  sum(field) {
    return this.fixedUnits.map(u => u[field]).reduce((a,b) => a + b, 0);
  }
}
