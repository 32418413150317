import {Component, OnDestroy, OnInit} from '@angular/core';
import { Title } from '@angular/platform-browser';
import {PayReportService} from "src/app/services/pay-report.service";
import {Observable} from "rxjs";
import {ActivatedRoute} from "@angular/router";
import {flatMap, map, mergeMap, takeWhile, tap} from "rxjs/operators";
import {PayReport} from "src/app/models/pay-report";

@Component({
  selector: 'app-pay-report',
  templateUrl: './pay-report.component.pug',
  styleUrls: ['./pay-report.component.sass']
})
export class PayReportComponent implements OnInit, OnDestroy {
  alive = true;
  report$: Observable<PayReport>;
  previousReport$: Observable<PayReport>;
  nextReport$: Observable<PayReport>;

  constructor(private route: ActivatedRoute,
              private payReportService: PayReportService,
              private titleService: Title,
              ) {
    this.route.params
      .pipe(takeWhile(_ => this.alive))
      .subscribe(params => {
        this.report$ = this.payReportService.get(parseInt(params.reportId));
        this.previousReport$ = this.payReportService.get(parseInt(params.reportId) - 1);
        this.nextReport$ = this.payReportService.get(parseInt(params.reportId) + 1);
      });
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.alive = false;
  }

  async startPrint(): Promise<void> {
    this.report$.subscribe(report => {
      this.setTitle( report.name );
      window.print();
      this.setTitle("Payreport");
    })
  }
  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }
}
