import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatToolbarModule} from "@angular/material/toolbar";
import {StepperComponent} from "./stepper/stepper.component";
import {MatStepperModule} from "@angular/material/stepper";
import {MatButtonModule} from "@angular/material/button";
import {NgxSpinnerModule} from 'ngx-spinner';
import { PayReportComponent } from './components/pay-report/pay-report.component';
import { LeftNavComponent } from './components/left-nav/left-nav.component';
import { TimeUnitTableComponent } from './components/time-unit-table/time-unit-table.component';
import { PayReportSummaryComponent } from './components/pay-report-summary/pay-report-summary.component';
import { PaystubComponent } from './components/paystub/paystub.component';
import { CallPayDetailComponent } from './components/call-pay-detail/call-pay-detail.component';
import { FixedUnitTableComponent } from './components/fixed-unit-table/fixed-unit-table.component';
import { EmployeesSummaryComponent } from './components/employees-summary/employees-summary.component';
import { Facilities } from './components/facilities/facilities.component';

@NgModule({
  declarations: [
    AppComponent,
    StepperComponent,
    PayReportComponent,
    LeftNavComponent,
    TimeUnitTableComponent,
    PayReportSummaryComponent,
    PaystubComponent,
    CallPayDetailComponent,
    FixedUnitTableComponent,
    EmployeesSummaryComponent,
    Facilities
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatStepperModule,
    MatButtonModule,
    NgxSpinnerModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [Title],
  bootstrap: [AppComponent]
})
export class AppModule { }
