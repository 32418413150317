import {Component, OnDestroy, OnInit} from '@angular/core';
import {PayReportService} from "src/app/services/pay-report.service";
import {Observable} from "rxjs";
import {PayReport} from "src/app/models/pay-report";
import {Router} from "@angular/router";
import {takeWhile} from "rxjs/operators";

@Component({
  selector: 'app-left-nav',
  templateUrl: './left-nav.component.pug',
  styleUrls: ['./left-nav.component.sass']
})
export class LeftNavComponent implements OnInit, OnDestroy {
  reports$: Observable<PayReport[]>;
  alive = true;

  constructor(private payReportService: PayReportService,
              private router: Router) {
    this.reports$ = this.payReportService.index();
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.alive = false;
  }

  startOver(): void {
    this.payReportService.clear()
      .pipe(takeWhile(_ => this.alive))
      .subscribe(_ => {
        this.reports$ = this.payReportService.index();
        this.router.navigateByUrl('/');
      });

  }

}
