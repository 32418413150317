import {FixedUnit} from "src/app/models/fixed-unit";
import {TimeUnit} from "src/app/models/time-unit";
import {CallPayUnit} from "src/app/models/call-pay-unit";

export interface PayReport {
  id: number,
  name: string,
  date: string,
  month: string,
  fixedUnits: FixedUnit[],
  timeUnits: TimeUnit[],
  callPayUnits: CallPayUnit[],
  totalUnits?: number,
  averageUnits?: number,
  doctorCode: string,
}

export function totalUnits(report: PayReport) {
  if(!report) return 0;
  let total: number = 0;
  // add callPayUnit[].units
  total = report.callPayUnits.reduce((total, callPayUnit) => total + callPayUnit.units, total);
  // add timeUnits[].timeUnits
  total = report.timeUnits.filter(timeUnit => !isNaN(timeUnit.timeUnits)).reduce((total, timeUnit) => total + timeUnit.timeUnits, total);
  // add fixedUnits[].units
  total = report.fixedUnits.reduce((total, fixedUnit) => total + fixedUnit.units, total);
  return total;
};