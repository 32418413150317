import {Component} from '@angular/core';
import {PayReportService} from "src/app/services/pay-report.service";
import {Observable} from "rxjs";
import {PayReport} from "src/app/models/pay-report";
import {Router} from "@angular/router";

@Component({
  selector: 'app-facilities',
  templateUrl: './facilities.component.pug',
  styleUrls: ['./facilities.component.sass']
})

export class Facilities {
  reports$: Observable<PayReport[]>;
  alive = true;
  facilities$: Observable<string[]>;
  exempted = {};

  constructor(private payReportService: PayReportService, private router: Router) {
    this.reports$ = this.payReportService.index();
    this.facilities$ = this.payReportService.facilities();
    try {
      this.exempted = JSON.parse(sessionStorage.getItem("exempted")) || this.defaultExemptionSet();
    } catch(e) {
      this.exempted = this.defaultExemptionSet();
    }
  }

  async toggleFacility({target:{value: facility, checked}}) {
    this.payReportService.exemptFacility(facility, !checked);
    if (checked) {
      delete this.exempted[facility];
    } else {
      this.exempted[facility] = true;
    }
    sessionStorage.setItem("exempted", JSON.stringify(this.exempted));
  }

  checkedFor(facility) {
    if(this.exempted[facility]) {
      return "";
    } else {
      return "checked";
    }
  }

  defaultExemptionSet() {
    const exempted = {
      "RIO RANCHO GI": true,
      "PRESBYTERIAN HOSPITAL": true
    };

    Object.keys(exempted).forEach(facility => this.payReportService.exemptFacility(facility, true));

    return exempted;
  }
}
