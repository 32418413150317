import {Component, Input, OnInit} from '@angular/core';
import {PayReport} from "src/app/models/pay-report";
import { dateToMMDDYYYY } from 'src/app/models/time-unit';

@Component({
  selector: 'app-call-pay-detail',
  templateUrl: './call-pay-detail.component.pug',
  styleUrls: ['./call-pay-detail.component.sass']
})
export class CallPayDetailComponent {
  @Input() payReport: PayReport;

  constructor() { }

  renderDate(date, handleBlank = false) {
    if(handleBlank && !date) return "";
    return dateToMMDDYYYY(date);
  }

  sum(field) {
    return this.payReport["callPayUnits"].map(u => u[field]).reduce((a,b) => a + b, 0);
  }
}
