import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {StepperComponent} from "src/app/stepper/stepper.component";
import {PayReportComponent} from "src/app/components/pay-report/pay-report.component";
import{EmployeesSummaryComponent} from "src/app/components/employees-summary/employees-summary.component"
import { Facilities } from './components/facilities/facilities.component';

const routes: Routes = [
  {path: '', component: StepperComponent},
  {path: 'facilities', component: Facilities},
  {path: 'overall', component: EmployeesSummaryComponent},
  { path: 'reports/:reportId', component: PayReportComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
