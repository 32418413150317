import {Component, Input } from '@angular/core';
import { PayReport } from 'src/app/models/pay-report';
import {TimeUnit } from "src/app/models/time-unit";

@Component({
  selector: 'app-time-unit-table',
  templateUrl: './time-unit-table.component.pug',
  styleUrls: ['./time-unit-table.component.sass']
})
export class TimeUnitTableComponent {
  @Input() timeUnits: TimeUnit[];
  @Input() payReport: PayReport;

  constructor() { }

  rows() {
    return this.timeUnits;
  }

  code(): string {
    if(this.payReport) {
      return this.payReport.doctorCode;
    } else {
      return null;
    }
  }

  sum(field) {
    return this.timeUnits.filter(tu => !tu.exempt).map(u => u[field]).reduce((a,b) => a + b, 0);
  }
}
