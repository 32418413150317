import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { PayReport, totalUnits } from "src/app/models/pay-report";
import { PayReportService } from "src/app/services/pay-report.service";

const sum = (property) => (sum, record) => sum + record[property];

@Component({
    selector: 'app-paystub',
    templateUrl: './paystub.component.pug',
    styleUrls: ['./paystub.component.sass']
})
export class PaystubComponent {
    @Input() payReport: PayReport;
    
    constructor(private payReportService: PayReportService) {

    }
    

    totalUnitsDisplay() {
        return this.payReport.totalUnits.toFixed(2);
    }

    averageUnitsDisplay() {
        return this.payReport.averageUnits.toFixed(2);
    }

    percentOfAverage() {
        return Math.floor(100 * this.payReport.totalUnits / this.payReport.averageUnits);
    }
    fixedUnitsDisplay() {
        return this.payReport.fixedUnits.reduce(sum('units'), 0).toFixed(2);
    }
    callPayUnitsDisplay() {
        return this.payReport.callPayUnits.reduce(sum('units'), 0).toFixed(2);
    }
    timeUnitsDisplay() {
        return this.payReport.timeUnits.filter(tu => !tu.exempt).reduce(sum('timeUnits'), 0).toFixed(2);
    }
}